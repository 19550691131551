import { Button, Checkbox, CheckboxGroup, Divider, Modal, TextArea, TextField } from '@nike/eds'
import { useEffect, useState } from 'react'
import { type GradingInstruction } from 'types/Instruction'

import './GradingInstructionEditor.scss'

interface GradingInstructionEditorProps {
  gradingInstruction: GradingInstruction
  mode: 'Create' | 'Edit'
  onSave: (gradingInstruction: GradingInstruction) => void
  onDismiss: () => void
}

export const GradingInstructionEditor = ({ gradingInstruction, mode, onSave, onDismiss }: GradingInstructionEditorProps) => {
  const [instruction, setInstruction] = useState<GradingInstruction>(gradingInstruction)
  const [hasClickedSave, setHasClickedSave] = useState<boolean>(false)
  const [allValidated, setAllValidated] = useState<boolean>(false)

  const STYLE_REGEX = /^[a-zA-Z0-9]{6}$/
  const COLOR_REGEX = /^[a-zA-Z0-9]{0,3}$/
  const DESCRIPTION_MAX_LENGTH = 255
  const INSTRUCTION_MAX_LENGTH = 255

  useEffect(() => {
    setAllValidated(isValid(instruction))
  }, [instruction])

  const save = () => {
    setInstruction(instruction)
    setHasClickedSave(true)
    if (isValid(instruction)) {
      onSave(instruction)
    }
  }

  const isValid = (instruction: GradingInstruction): boolean => {
    return STYLE_REGEX.test(instruction.style) &&
      COLOR_REGEX.test(instruction.color) &&
      instruction.description.length <= DESCRIPTION_MAX_LENGTH &&
      (instruction.instruction !== '' && instruction.instruction.length <= INSTRUCTION_MAX_LENGTH) &&
      instruction.acceptableGrades.length > 0
  }

  const handleChange = (field: string, value: string) => {
    setInstruction(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  const handleCheckBox = async (id: string, checked: boolean) => {
    let grade: string | undefined
    switch (id) {
      case 'aGrade': grade = 'A_GRADE'; break
      case 'bGrade': grade = 'B_GRADE'; break
      case 'cGrade': grade = 'C_GRADE'; break
      case 'hash': grade = 'HASH'; break
    }
    if (checked) {
      setInstruction(prevState => ({
        ...prevState,
        acceptableGrades: [...prevState.acceptableGrades, grade!]
      }))
    } else {
      setInstruction(prevState => ({
        ...prevState,
        acceptableGrades: prevState.acceptableGrades.filter(g => g !== grade!)
      }))
    }
  }
  return (
    <Modal
      isOpen={true}
      headerSlot={mode === 'Create' ? 'Create grading instruction' : 'Edit grading instruction'}
      footerSlot={(<Button disabled={!allValidated} size="medium" variant="primary" onClick={() => { save() }}>Save</Button>)}
      onDismiss={onDismiss}
      hideFade={true}>
      <div className="instruction-modal">
        <div className="product-code">
          <div className="style w-1/5">
            <TextField
              id="style"
              type="text"
              label="Style"
              value={instruction.style}
              maxChars={6}
              onChange={(e) => {
                handleChange('style', e.target.value)
              }}
              hasErrors={hasClickedSave && !STYLE_REGEX.test(instruction.style)}
              errorMessage='6 numbers/letters'
            />
          </div>
          <div className="w-1/5">
            <TextField
              id="color"
              type="text"
              label="Color"
              value={instruction.color}
              maxChars={3}
              onChange={(e) => {
                handleChange('color', e.target.value)
              }}
              hasErrors={hasClickedSave && !COLOR_REGEX.test(instruction.color)}
              errorMessage='0 or 3 numbers/letters'
            />
          </div>
        </div>

        <Divider />

        <TextArea
          showCount={true}
          id="description"
          label="Description"
          subtitle="Text to provide context and legibility within the admin UI"
          value={instruction.description}
          maxChars={255}
          minRows={2}
          onChange={(e) => { handleChange('description', e.target.value) }}
          hasErrors={hasClickedSave && instruction.description.length > DESCRIPTION_MAX_LENGTH}
          errorMessage={'This field is <= 255 characters'}
        />
        <TextArea
          showCount={true}
          id="instruction"
          label="Special Instruction"
          subtitle="What will actually displayed during unit-handling"
          value={instruction.instruction}
          maxChars={255}
          minRows={2}
          onChange={(e) => { handleChange('instruction', e.target.value) }}
          hasErrors={hasClickedSave && (instruction.instruction === '' || instruction.instruction.length > INSTRUCTION_MAX_LENGTH)}
          errorMessage={'This field is <= 255 characters'}
        />
        <CheckboxGroup
          id="availableGrades"
          label="Available grades"
          name="availableGrades"
          orientation='horizontal'
          onChange={(e) => { handleCheckBox(e.target.id, e.target.checked) }}
          hasErrors={hasClickedSave && instruction.acceptableGrades.length === 0}
          errorMessage='At least one grade must be selected'>
          <Checkbox label="A" id="aGrade" checked={instruction.acceptableGrades.includes('A_GRADE')} />
          <Checkbox label="B" id="bGrade" checked={instruction.acceptableGrades.includes('B_GRADE')} />
          <Checkbox label="C" id="cGrade" checked={instruction.acceptableGrades.includes('C_GRADE')} />
          <Checkbox label="Hash" id="hash" checked={instruction.acceptableGrades.includes('HASH')} />
        </CheckboxGroup>
      </div>
    </Modal>
  )
}
