import { IconButton } from '@nike/eds'
import { type ColumnFiltersState, type SortingState } from '@tanstack/react-table'
import { useCreateGradingInstructionsMutation, useDeleteGradingInstructionsMutation, useSearchGradingInstructionsQuery, useUpdateGradingInstructionsMutation } from 'api/instruction'
import { ConfirmationModal } from 'components/confirmation-modal'
import { ActionLocationType, TableView } from 'components/tableview'
import { ColumnType } from 'components/tableview/Filter'
import { usePaginatedData } from 'components/tableview/usePaginatedData'
import { useTableState } from 'components/tableview/useTableState'
import { useEffect, useMemo, useState } from 'react'
import { showSnackbar } from 'redux/actions/snackbar.action'
import { GradingInstruction, type QueryResult } from 'types'

import { GradingInstructionEditor } from './components'

export const GradingInstructionOverview = () => {
  const tableState = useTableState()
  const columnFiltersTransformer = (filters: ColumnFiltersState): ColumnFiltersState => filters.map(filter => {
    if (filter.id === 'style') {
      return { id: 'productCode', value: filter.value }
    } else if (filter.id === 'description') {
      return { id: 'label', value: filter.value }
    }
    return filter
  })
  const sortingTransformer = (sorting: SortingState): SortingState => sorting.map(sort => {
    if (sort.id === 'style') {
      return { desc: sort.desc, id: 'productCode' }
    } else if (sort.id === 'description') {
      return { desc: sort.desc, id: 'label' }
    }
    return sort
  })
  const data = usePaginatedData<QueryResult<GradingInstruction>>(useSearchGradingInstructionsQuery, tableState.pagination, tableState.columnFilters, tableState.sorting, undefined, columnFiltersTransformer, sortingTransformer)
  const FILTER_INPUT_DELAY_MS = 500

  const [createGradingInstructions, { isSuccess: createSuccessful }] = useCreateGradingInstructionsMutation()
  const [updateGradingInstructions, { isSuccess: updateSuccessful }] = useUpdateGradingInstructionsMutation()
  const [deleteGradingInstructions] = useDeleteGradingInstructionsMutation()
  const [selectedGradingInstruction, setSelectedGradingInstruction] = useState<GradingInstruction | null>(null)
  const [modal, setModal] = useState<'Create' | 'Edit'>('Create')
  const [toBeDeleted, setToBeDeleted] = useState<number | null>(null)

  const columns = useMemo(() => [
    { id: 'style', header: 'Style', type: ColumnType.TEXT, columnWidth: 4, enableColumnFilter: true, enableSorting: true },
    { id: 'color', header: 'Color', type: ColumnType.TEXT, columnWidth: 4, enableColumnFilter: false, enableSorting: false },
    { id: 'description', header: 'Description', type: ColumnType.TEXT, columnWidth: 12, enableColumnFilter: true, enableSorting: true },
    { id: 'acceptableGrades', header: 'Allowed grades', type: ColumnType.ARRAY, columnWidth: 8, enableColumnFilter: false, enableSorting: false },
    { id: 'modifiedBy', header: 'Modified by', type: ColumnType.TEXT, columnWidth: 8, enableColumnFilter: false, enableSorting: true },
    { id: 'lastModified', header: 'Last Modified', type: ColumnType.DATE, columnWidth: 4, enableColumnFilter: false, enableSorting: true }
  ], [])

  const onCloseModal = (gradingInstruction: GradingInstruction) => {
    modal === 'Create' ? createGradingInstructions(gradingInstruction) : updateGradingInstructions(gradingInstruction)
  }

  const onCreate = () => {
    setModal('Create')
    setSelectedGradingInstruction(new GradingInstruction())
  }

  const onEdit = (row: GradingInstruction) => {
    setModal('Edit')
    setSelectedGradingInstruction(row)
  }

  const onDelete = (id: number) => {
    deleteGradingInstructions(id)
      .catch(() => {
        showSnackbar('Failed to delete instruction', 'error')
      }).finally(() => {
        setToBeDeleted(null)
      })
  }

  const onCopy = (row: GradingInstruction) => {
    setModal('Create')
    const copy = new GradingInstruction()
    copy.description = row.description
    copy.instruction = row.instruction
    copy.acceptableGrades = row.acceptableGrades
    setSelectedGradingInstruction(copy)
  }

  useEffect(() => {
    setSelectedGradingInstruction(null)
  }, [createSuccessful, updateSuccessful])

  const rowButtons = [
    {
      label: 'Edit',
      icon: 'Edit',
      hoverText: 'Edit',
      columnWidth: 4,
      onClick: (row: GradingInstruction) => { onEdit(row) }
    },
    {
      label: 'Delete',
      icon: 'Delete',
      hoverText: 'Delete',
      columnWidth: 4,
      onClick: (row: GradingInstruction) => { setToBeDeleted(row.id) }
    },
    {
      label: 'Copy',
      icon: 'CopyPaste',
      hoverText: 'Copy',
      columnWidth: 4,
      onClick: (row: GradingInstruction) => { onCopy(row) }
    }
  ]

  return (
    <>
      <div className="fixed bottom-24 right-4">
        <IconButton
          icon="CreateRule"
          onClick={onCreate}
          label={''}
          title='Create new instruction'
          size="large">
        </IconButton>
      </div>
      <TableView
        columns={columns}
        data={data?.results ?? []}
        rowCount={data?.totalCount ?? 0}
        textFilterDelay={FILTER_INPUT_DELAY_MS}
        tableState={tableState}
        actions={rowButtons}
        actionLocation={ActionLocationType.LAST}
        actionsWidth={48}
        defaultSorting={[{ desc: true, id: 'lastModified' }]}
      />
      {selectedGradingInstruction &&
        <GradingInstructionEditor
          gradingInstruction={selectedGradingInstruction}
          mode={modal}
          onSave={onCloseModal}
          onDismiss={() => { setSelectedGradingInstruction(null) }} />
      }
      {toBeDeleted && <ConfirmationModal
        onTrigger={() => { onDelete(toBeDeleted) }}
        onCancel={() => { setToBeDeleted(null) }}
        title="Delete instruction"
        message="Are you sure you want to delete this instruction?"
        iconClass=""
        confirmText="Confirm"
      />}
    </>
  )
}
