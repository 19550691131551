export class GradingInstructionDTO {
  id: number | null
  productCode: string
  label: string
  value: string
  acceptableGrades: string[]
  modifiedBy: string | null
  lastModified: Date | null

  constructor (instruction?: GradingInstruction) {
    this.id = instruction?.id ?? null
    this.productCode = instruction?.style && instruction?.color ? `${instruction?.style}-${instruction?.color}` : ''
    this.label = instruction?.description ?? ''
    this.value = instruction?.instruction ?? ''
    this.acceptableGrades = instruction?.acceptableGrades ?? []
    this.modifiedBy = instruction?.modifiedBy ?? null
    this.lastModified = instruction?.lastModified ?? null
  }
}

export class GradingInstruction {
  id: number | null
  style: string
  color: string
  description: string
  instruction: string
  acceptableGrades: string[]
  modifiedBy: string | null
  lastModified: Date | null

  constructor (dto?: GradingInstructionDTO) {
    this.id = dto?.id ?? null
    this.style = dto?.productCode.split('-')[0] ?? ''
    this.color = dto?.productCode.split('-')[1] ?? ''
    this.description = dto?.label ?? ''
    this.instruction = dto?.value ?? ''
    this.acceptableGrades = dto?.acceptableGrades ?? []
    this.modifiedBy = dto?.modifiedBy ?? null
    this.lastModified = dto?.lastModified ?? null
  }
}
