import { mainAdminSplitApi } from 'redux/store/mainSplitApi'
import { type GradingInstructionDTO, GradingInstruction, type QueryParameters, type QueryResult } from 'types'

const toGradingInstructionDTO = (instruction: GradingInstruction): GradingInstructionDTO => {
  return {
    id: instruction.id,
    productCode: instruction.color !== '' ? `${instruction.style}-${instruction.color}` : instruction.style,
    label: instruction.description,
    value: instruction.instruction,
    acceptableGrades: instruction.acceptableGrades,
    lastModified: instruction.lastModified,
    modifiedBy: instruction.modifiedBy
  }
}

const instructionsApi = mainAdminSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // Partial allows the query parameters to be optional, in which case they
    // are omitted from the request URI (which the backend knows how to handle)
    searchGradingInstructions: builder.query<QueryResult<GradingInstruction>, Partial<QueryParameters<GradingInstruction>>>({
      query: (parameters) => {
        return {
          url: '/instructions/grading',
          params: parameters
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [...result.results.map(({ id }) => ({ type: 'GradingInstructions' as const, id: id != null ? id : undefined })), 'GradingInstructions']
          : ['GradingInstructions'],
      transformResponse: (response: QueryResult<GradingInstructionDTO>) => ({
        ...response,
        results: response.results.map((instruction) => new GradingInstruction(instruction))
      })
    }),
    createGradingInstructions: builder.mutation<GradingInstruction, GradingInstruction>({
      query: (instruction) => ({
        url: '/instructions/grading',
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: toGradingInstructionDTO(instruction)
      }),
      invalidatesTags: ['GradingInstructions'],
      transformResponse: (response: GradingInstructionDTO) => new GradingInstruction(response)
    }),
    updateGradingInstructions: builder.mutation<GradingInstruction, GradingInstruction>({
      query: (instruction) => ({
        url: '/instructions/grading',
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        body: toGradingInstructionDTO(instruction)
      }),
      invalidatesTags: (result, error, arg) => {
        const id = arg.id != null ? arg.id : undefined
        return [{ type: 'GradingInstructions', id }]
      },
      transformResponse: (response: GradingInstructionDTO) => new GradingInstruction(response)
    }),
    deleteGradingInstructions: builder.mutation<void, number>({
      query: (id) => ({
        url: `/instructions/grading/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'GradingInstructions', id }]
    })
  })
})

export const { useSearchGradingInstructionsQuery, useCreateGradingInstructionsMutation, useUpdateGradingInstructionsMutation, useDeleteGradingInstructionsMutation } = instructionsApi
