
import { Modal, Button, Icon } from '@nike/eds'
import { type ReactNode, useState } from 'react'

import 'components/icon/icon.css'

interface ConfirmationModalProps {
  onTrigger: () => void
  onCancel?: () => void
  title: string
  message: string
  icon?: string | ReactNode
  iconSize?: 's' | 'm' | 'l'
  iconClass: string
  confirmText: string
}

export const ConfirmationModal = ({ onTrigger, onCancel, title, message, icon, iconSize, confirmText, iconClass }: ConfirmationModalProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(icon === undefined)

  const handleTriggerClick = () => {
    setShowConfirmModal(true)
  }

  const confirmTrigger = () => {
    onTrigger()
    setShowConfirmModal(false)
  }

  const renderIcon = () => {
    if (typeof icon === 'string') {
      return <Icon name={icon} size={iconSize ?? 'l'} title={title} />
    }
    return <div className={`${iconSize ?? 'l'}_icon`}>{icon}</div>
  }

  const onCancelled = () => {
    if (onCancel) {
      onCancel()
    }
    setShowConfirmModal(false)
  }

  return (
    <>
      {icon &&
        <button onClick={handleTriggerClick} className={iconClass}>
          {renderIcon()}
        </button>
      }

      {showConfirmModal && (
        <Modal
          isOpen={showConfirmModal}
          onDismiss={onCancelled}
          headerSlot={
            <div className="flex justify-between items-center">
              <h3>{title}</h3>
            </div>
          }
          footerSlot={
            <div className="flex justify-end space-x-4">
              <Button onClick={onCancelled}>Cancel</Button>
              <Button onClick={confirmTrigger}>{confirmText}</Button>
            </div>
          }
        >
          <p>{message}</p>
        </Modal>
      )}
    </>
  )
}
